import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import bnr1 from './../../images/line2.png';
import team1 from '../../images/team/tania.jpg';
import team2 from './../../images/team/sadia.png';
import team3 from './../../images/team/roksana.jpg';

const teamBlog = [
	{
		image: team1,
		name: "Tania Panna",
		details: "Hi, I am Tania Panna and I am a pre school teacher.\n" +
			"\n" +
			" I love working with children and seeing their eyes light up when they discover something new. Working at Care for child development Ltd.  is great because we are teaching the future about recycling and being green!\n" +
			"\n" +
			"I work 9:00am-3:00 saturday through Thursday and would love the opportunity to speak with you about your child’s needs. If you have any questions or concerns, please feel free to speak with me in person. My phone number is 01622824016."
	},
	{
		image: team2,
		name: "Sadia Hasan",
		details: "Hello, I’m Sadia Hasan. and I am one of the teachers  in Care for child development Ltd.  .  I live with my husband  and one children.  I am well trained  in Preschool and Child Development and the other in Infant Curriculum, Development and Care.\n" +
			"\n" +
			"In my free time I enjoy running, Zumba, shopping and being in the sunshine.   I chose to work with children because ever since I was a little girl I always wanted to be a teacher, so I decided to follow my dream.\n" +
			"\n" +
			"I find inspiration everyday through working with the babies in the nursery and knowing that I am part of their first school experience.  I feel that it is a gift to me to help them explore the world around them.  I enjoy working at All About Kids because the staff is terrific to work with.  The name of the school says it all I think!  My hours are 9:00 a.m.-5:30 p.m. if you need anything please let me know!\n" +
			"My phone number is01942510688."
	},
	{
		image: team3,
		name: "Roksana Mili",
		details: "Hi, my name is Roksana Mili. I am a very caring and bubbly person. I always have a smile on my face, am easy to talk to, and easy to get to know. Working with kids has always been a passion of mine, this is why I am so excited to work at All About Kids in the Early Toddler Classroom and pre school. Kids are so funny and it is always enjoyable watching them learn and grow. Now I work at Care for child development Ltd.My hours are Saturday through Thursday from 7:30 a.m. to 3:30 p.m., please let me know if I can help you with anything!My phone number is 01737938282."
	},
];

class Teachers extends Component{
	render(){
		return(
			<Fragment>
				<Header />
					<div className="page-content">
						<PageTitle motherMenu="Teachers" activeMenu="Teachers" />
						<div className="content-block">
							<div className="section-full bg-white content-inner" style={{backgroundImage:"url("+ bnr1 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
								<div className="container">
									<div className="section-head text-center">
										<h2 className="head-title text-secondry">About the Teachers</h2>
										<p>
											We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs
										</p>
									</div>
									<div className="row">
										{teamBlog.map((data,index)=>(
											<div className="col-lg-3 col-md-6 col-sm-6" key={index}>
												 <div className="dlab-box box-frame1 team-box m-b40">
													<div className="dlab-thum"> 
														<Link to={"/teachers-details"}>
															<img style={{objectFit: "fill", height: 350, width: 350}} src={data.image} alt="" />
														</Link>
														<div className="overlay-bx">
															<h5 className="team-title"><Link to={"/teachers-details"}>{data.name}</Link></h5>
															<span className="team-info">Teachers</span>
															<ul className="list-inline">
																<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-google-plus"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>	
								</div>
							</div>
						</div>
					</div>
				<Footer />
			</Fragment>
		)
	}
}

export default Teachers;